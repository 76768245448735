const appStore = {
    state: {
        examineNum: 0,
        selfInfo: {}, //校友信息
        OwnedPermissionsList: [], //按钮权限设置
        menuList: [], //菜单设置
        isShowTitle:false,//是否展示头衔届数
    },
    mutations: {
        examineNumFun(state, value) {

            state.examineNum = value
        },
        updatePermission(state, value) {
            state.OwnedPermissionsList = value
        },
        updateMenu(state, value) {
            state.menuList = value
        },
        updateSelfInfo(state, value) {
            state.selfInfo = value
        },
        updateTitle(state,value){
          state.isShowTitle =value
        }
    },
    actions: {
        changeExamine(context, value) {

            context.commit('examineNumFun', value)
        },
        totleFunDel(context, value) {
            context.commit('totleFun', value - 1)
        },
        updatePermissionActions(context, value) {
            context.commit('updatePermission', value)
        },
        updateMenuActions(context, value) {
            context.commit('updateMenu', value)
        },
        updateSelfInfoActions(context, value) {
            context.commit('updateSelfInfo', value)
        },
        updateTitleAtions(context,value){
          context.commit('updateTitle', value)
        }
    }
}

export default appStore;