import axios from '@/utils/axios'
//获取有权限的菜单
// Menu/GetAll GetGrantedMenus
export function GetGrantedMenus() {
    return axios({
        url: '/CommercialClubManagement/Menu/GetGrantedMenus',
        method: 'get'
    })
}

//获取所拥有的权限：
export function GetOwnedPermissions() {
    return axios({
        url: '/CommercialClubManagement/Permission/GetOwnedPermissions',
        method: 'get'
    })
}