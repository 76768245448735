import axios from '@/utils/axios'

export function login(data) {
    return axios({
        url: '/CommercialClubUser/CommercialClubUser/ManagerLogin',
        method: 'post',
        data
    })
}

//用户登出
export function logout(data) {
    return axios({
        url: '/CommercialClubUser/CommercialClubUser/Logout',
        method: 'post',
        data
    })
}

//获取自己的校友信息
export function getSelfMember() {
    return axios({
        url: '/CommercialClubUser/CommercialClubUser/GetCurrentUserInfo?groupId=',
        method: 'get'
    })
}

//修改密码
export function updatePassword(data) {
    return axios({
        url: '/CommercialClubUser/CommercialClubUser/UpdatePassword',
        method: 'post',
        data
    })
}
// CommercialClubUser/CommercialClubUser/UpdateUserNickName

export  function updateUserNickName (data){
    return axios({
        url: '/CommercialClubUser/CommercialClubUser/UpdateUserNickName',
        method: 'post',
        data
    })
}