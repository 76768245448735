import axios from '@/utils/axios'

//获取【届】初始化数据
export function getTermDefinedInitData() {
    return axios({
        url: '/CommercialClubManagement/BusinessCardGroup/GetTermDefinedInitData?groupId=',
        method: 'post'
    })
}


//新增【届】数据
export function addTermDefined(data) {
    return axios({
        url: '/CommercialClubManagement/BusinessCardGroup/AddTermDefined',
        method: 'post',
        data
    })
}

//更新【届】数据
export function updateTermDefined(data) {
    return axios({
        url: '/CommercialClubManagement/BusinessCardGroup/UpdateTermDefined',
        method: 'post',
        data
    })
}

//获取【届】所有数据
export function getTermDefineds() {
    return axios({
        url: '/CommercialClubManagement/BusinessCardGroup/GetTermDefineds?groupId=',
        method: 'get'
    })
}
/**
 * 
 * GET /CommercialClubManagement/BusinessCardGroup/GetCurrentAndFutureAndTemporaryTermDefineds
获取当前届及临时届


/CommercialClubManagement/BusinessCardGroup/GetCurrentAndTemporaryTermDefineds

 */
export function GetCurrentAndTemporaryTermDefineds() {
    return axios({
        url: '/CommercialClubManagement/BusinessCardGroup/GetCurrentAndTemporaryTermDefineds?groupId=',
        method: 'get'
    })
}
//删除【届】数据
export function deleteTermDefined(id) {
    return axios({
        url: '/CommercialClubManagement/BusinessCardGroup/DeleteTermDefined?termDefinedId=' + id,
        method: 'post'
    })
}

//获取所有的费用
export function getAll(data) {
    return axios({
        url: '/CommercialClubManagement/MemberFee/GetAll',
        method: 'post',
        data
    })
}

//新增或者修改头衔
export function addOrUpdate(data) {
    return axios({
        url: '/CommercialClubManagement/MemberTitle/AddOrUpdate',
        method: 'post',
        data
    })
}

//获取所有的头衔
export function getTitleLists(data) {
    return axios({
        url: '/CommercialClubManagement/MemberTitle/GetLists',
        method: 'post',
        data
    })
}

//头衔拖拽排序POST /CommercialClubManagement/MemberTitle/DragSort
export function dragSortMemberTitle(data) {
    return axios({
        url: '/CommercialClubManagement/MemberTitle/DragSort',
        method: 'post',
        data
    })
}

//新增费用
export function addMemberFee(data) {
    return axios({
        url: '/CommercialClubManagement/MemberFee/Add',
        method: 'post',
        data
    })
}
//更新费用
export function updateMemberFee(data) {
    return axios({
        url: '/CommercialClubManagement/MemberFee/Update',
        method: 'post',
        data
    })
}
//删除费用
export function deleteMemberFee(id) {
    return axios({
        url: '/CommercialClubManagement/MemberFee/Delete?feeId=' + id,
        method: 'post',

    })
}
//审核后是否收费
export function chargeStateChange(data) {
    return axios({
        url: '/CommercialClubManagement/BusinessCardGroup/ChargeStateChange',
        method: 'post',
        data
    })
}

//更新头衔的是否需要收费
export function chargeStateOnChange(data) {
    return axios({
        url: '/CommercialClubManagement/MemberTitle/ChargeStateOnChange',
        method: 'post',
        data
    })
}
//删除头衔
export function deleteMemberTitle(id) {
    return axios({
        url: '/CommercialClubManagement/MemberTitle/Delete?titleId=' + id,
        method: 'post',

    })
}
//批量删除头衔
export function batchDelete(data) {
    return axios({
        url: '/CommercialClubManagement/MemberTitle/BatchDelete',
        method: 'post',
        data

    })
}

//新增入会须知
export function addNotice(data) {
    return axios({
        url: '/CommercialClubManagement/BusinessCardGroup/AddNotice',
        method: 'post',
        data
    })
}

//获取入会须知
export function getNotice() {
    return axios({
        url: '/CommercialClubManagement/BusinessCardGroup/GetNotice?groupId=',
        method: 'get',

    })
}

//更新入会须知
export function updateNotice(data) {
    return axios({
        url: '/CommercialClubManagement/BusinessCardGroup/UpdateNotice',
        method: 'post',
        data
    })
}

//GET /CommercialClubManagement/BusinessCardGroup/GetGroupInfo
//获取校友汇信息
export function getGroupInfo() {
    return axios({
        url: '/CommercialClubManagement/BusinessCardGroup/GetGroupInfo?groupId=',
        method: 'get'
    })
}

///Company/Search  企业类别
export function searchCompany() {
    return axios({
        url: 'Company/Search',
        method: 'get'
    })
}

//POST /CommercialClubManagement/Member/AddMember新增校友信息
export function addMember(data) {
    return axios({
        url: '/CommercialClubManagement/Member/AddMember',
        method: 'post',
        data
    })
}

//POST POST /CommercialClubManagement/Member/UpdateMember修改校友信息
export function updateMember(data) {
    return axios({
        url: '/CommercialClubManagement/Member/UpdateMember',
        method: 'post',
        data
    })
}
/**
 * GET /CommercialClubManagement/Member/GetMemberStatistics
获取校友统计
 */

export function GetMemberStatistics(data) {
    return axios({
        url: '/CommercialClubManagement/Member/GetMemberStatistics',
        method: 'POST',
        data
    })
}

/**
 * POST /CommercialClubManagement/Member/GetMemberElementInfoList
获取当前届校友的基本信息列表
 */
export function getMemberElementInfoList(data) {
    return axios({
        url: '/CommercialClubManagement/Member/GetMemberElementInfoList',
        method: 'post',
        data
    })
}

/**
 * POST /CommercialClubManagement/BusinessCardGroup/DeleteLeader
从领导风采中移除一位展示的校友
 */
export function deleteLeader(id) {
    return axios({
        url: '/CommercialClubManagement/BusinessCardGroup/DeleteLeader?memeberId=' + id,
        method: 'post'
    })
}

/**
 * POST /CommercialClubManagement/BusinessCardGroup/BatchDeleteLeader
从领导风采中批量移除展示的校友
 */
export function batchDeleteLeader(data) {
    return axios({
        url: '/CommercialClubManagement/BusinessCardGroup/BatchDeleteLeader',
        method: 'post',
        data
    })
}

/**
 * POST /CommercialClubManagement/BusinessCardGroup/GroupLeaderDragSort
领导风采拖拽排序
 */

export function GroupLeaderDragSort(data) {
    return axios({
        url: '/CommercialClubManagement/BusinessCardGroup/GroupLeaderDragSort',
        method: 'post',
        data
    })
}
/**
 * GET /CommercialClubManagement/BusinessCardGroup/GetSecondExamineState
获取是否启用复审状态
 */

export function GetSecondExamineState() {
    return axios({
        url: '/CommercialClubManagement/BusinessCardGroup/GetSecondExamineState?groupId=',
        method: 'get'
    })
}

/**
 * POST /CommercialClubManagement/BusinessCardGroup/ChangeExamineProcess
改变审核流程（即是否启用复审
 */
export function ChangeExamineProcess(data) {
    return axios({
        url: '/CommercialClubManagement/BusinessCardGroup/ChangeExamineProcess',
        method: 'post',
        data
    })
}

/**
 * POST /CommercialClubManagement/MemberFee/GetByTitleAndTermId
通过头衔和届获取费用信息
 */

export function GetByTitleAndTermId(data) {
    return axios({
        url: '/CommercialClubManagement/MemberFee/GetByTitleAndTermId',
        method: 'post',
        data
    })
}

//获取企业规模

export function GetCompanyScaleList(data) {
    return axios({
        url: '/Common/GetCompanyScaleList',
        method: 'get',
        data
    })
}

//编辑校友企业信息
export function EditMemberCompanyInfo(data) {
    return axios({
        url: '/CommercialClubManagement/Member/EditMemberCompanyInfo',
        method: 'post',
        data
    })
}

//获取校友单位的所有员工名片
export function GetMemberCompanyStaffs(data) {
    return axios({
        url: '/CommercialClubManagement/Member/GetMemberCompanyStaffs',
        method: 'post',
        data
    })
}

//编辑校友单位的员工名片

export function EditMemberCompanyStaff(data) {
    return axios({
        url: '/CommercialClubManagement/Member/EditMemberCompanyStaff',
        method: 'post',
        data
    })
}

//设置校友汇信息
export function SetGroupInfo(data) {
    return axios({
        url: '/CommercialClubManagement/BusinessCardGroup/SetGroupInfo',
        method: 'post',
        data
    })
}

//获取积分设置
export function GetIntegral(data) {
    return axios({
        url: '/CommercialClubManagement/BusinessCardGroup/GetIntegral?groupId=',
        method: 'get',
        data
    })
}
//设置积分
export function SetIntegral(data) {
    return axios({
        url: '/CommercialClubManagement/BusinessCardGroup/SetIntegral',
        method: 'post',
        data
    })
}
export function GetInviteIntegralInfo() {
    return axios({
        url: '/CommercialClubManagement/BusinessCardGroup/GetInviteIntegralInfo',
        method: 'get'
    })
}

export function SetInviteIntegral(data) {
    return axios({
        url: '/CommercialClubManagement/BusinessCardGroup/SetInviteIntegral',
        method: 'post',
        data
    })
}

//获取对公转账信息
export function GetCorporateTransferInfo(id) {
    return axios({
        url: '/CommercialClubManagement/MemberFee/GetCorporateTransferInfo?titleFeeRecordId=' + id,
        method: 'get'
    })
}

//修改对公转账状态（即审核对公转账）
export function ChangeCorporateTransferState(data) {
    return axios({
        url: '/CommercialClubManagement/MemberFee/ChangeCorporateTransferState',
        method: 'post',
        data
    })
}

//获取所有俱乐部
export function GetAllGroupClub() {
    return axios({
        url: '/CommercialClubManagement/GroupClub/GetAll',
        method: 'get'
    })
}

//新增俱乐部
export function AddGroupClub(data) {
    return axios({
        url: '/CommercialClubManagement/GroupClub/Add',
        method: 'post',
        data
    })
}

//删除俱乐部
export function DeleteGroupClub(data) {
    return axios({
        url: '/CommercialClubManagement/GroupClub/Delete',
        method: 'post',
        data
    })
}

//新增职位
export function AddGroupJob(data) {
    return axios({
        url: '/CommercialClubManagement/GroupJob/Add',
        method: 'post',
        data
    })
}
//获取所有职位
export function GetAllGroupJob() {
    return axios({
        url: '/CommercialClubManagement/GroupJob/GetAll',
        method: 'get',
    })
}

// 修改职位
export function UpdateGroupJob(data) {
    return axios({
        url: '/CommercialClubManagement/GroupJob/Update',
        method: 'post',
        data
    })
}

//删除职位
export function DeleteGroupJob(data) {
    return axios({
        url: '/CommercialClubManagement/GroupJob/Delete',
        method: 'post',
        data
    })
}

//改变显示顺序
export function BatchChangeSort(data) {
    return axios({
        url: '/CommercialClubManagement/GroupJob/BatchChangeSort',
        method: 'post',
        data
    })
}

//改变显示顺序
export function GetPagedClubMembers(data) {
    return axios({
        url: '/CommercialClubManagement/GroupClub/GetPagedClubMembers',
        method: 'post',
        data
    })
}

//新增俱乐部成员
export function AddGroupClubMember(data) {
    return axios({
        url: '/CommercialClubManagement/GroupClub/AddMember',
        method: 'post',
        data
    })
}

//改变显示顺序
export function ChangeDisplayOrderGroupClub(data) {
    return axios({
        url: '/CommercialClubManagement/GroupClub/ChangeDisplayOrder',
        method: 'post',
        data
    })
}

//改变校友汇校友的显示顺序
export function ChangeDisplayOrderMember(data) {
    return axios({
        url: '/CommercialClubManagement/Member/ChangeDisplayOrder',
        method: 'post',
        data
    })
}

//改获取所以的企业行业
export function GetAllIndustry(data) {
    return axios({
        url: '/CommercialClubManagement/Member/GetAllIndustry',
        method: 'post',
        data
    })
}

// 删除俱乐部成员
export function DeleteMemberGroupClub(data) {
    return axios({
        url: '/CommercialClubManagement/GroupClub/DeleteMember',
        method: 'post',
        data
    })
}





//批量删除职位
export function BatchDelete(data) {
    return axios({
        url: '/CommercialClubManagement/GroupJob/BatchDelete',
        method: 'post',
        data
    })
}


//编辑校友单位

export function EditClubMemberCompanyInfo(data) {
    return axios({
        url: '/CommercialClubManagement/GroupClub/EditClubMemberCompanyInfo',
        method: 'post',
        data
    })
}

//编辑校友汇俱乐部配置（小程序分会与俱乐部头部配置）
export function EditClubConfiguration(data) {
    return axios({
        url: '/CommercialClubManagement/GroupClub/EditClubConfiguration',
        method: 'post',
        data
    })
}

//获取校友汇俱乐部配置（暂时用于小程序分会与俱乐部头部配置）
export function GetClubConfiguration() {
    return axios({
        url: '/CommercialClubManagement/GroupClub/GetClubConfiguration?groupId=',
        method: 'get'
    })
}

//新增俱乐部管理员
export function AddClubManager(data) {
    return axios({
        url: '/CommercialClubManagement/GroupClub/AddClubManager',
        method: 'post',
        data
    })
}


//设置校友汇收款信息
export function SetGroupPayeeInfo(data) {
    return axios({
        url: '/CommercialClubManagement/BusinessCardGroup/SetGroupPayeeInfo',
        method: 'post',
        data
    })
}


//获取初始届数

export function GetSchoolStartYear() {
    return axios({
        url: '/CommercialClubManagement/BusinessCardGroup/GetSchoolStartYear',
        method: 'get',

    })
}

//设置初始届数

export function SetSchoolStartYear(data) {
    return axios({
        url: '/CommercialClubManagement/BusinessCardGroup/SetSchoolStartYear',
        method: 'post',
        data
    })
}

//新增专业
export function AddSpecializedSubject(data) {
    return axios({
        url: '/CommercialClubManagement/SpecializedSubject/Add',
        method: 'post',
        data
    })
}
//修改专业
export function UpdateSpecializedSubject(data) {
    return axios({
        url: '/CommercialClubManagement/SpecializedSubject/Update',
        method: 'post',
        data
    })
}

//获取所有专业
export function GetAllSpecializedSubject() {
    return axios({
        url: '/CommercialClubManagement/SpecializedSubject/GetAll',
        method: 'get',
    })
}

//删除专业
export function DeleteSpecializedSubject(data) {
    return axios({
        url: '/CommercialClubManagement/SpecializedSubject/Delete',
        method: 'post',
        data
    })
}

//批量删除专业
export function BatchDeleteSpecializedSubject(data) {
    return axios({
        url: '/CommercialClubManagement/SpecializedSubject/BatchDelete',
        method: 'post',
        data
    })
}

//改变显示顺序
export function ChangeDisplayOrderSpecializedSubject(data) {
    return axios({
        url: '/CommercialClubManagement/SpecializedSubject/ChangeDisplayOrder',
        method: 'post',
        data
    })
}
//修改级别
export function ModifyLevel(data) {
    return axios({
        url: '/CommercialClubManagement/MemberTitle/ModifyLevel',
        method: 'post',
        data
    })
}

//获取积分抵扣设置
export function GetIntegralDeductionSetting() {
    return axios({
        url: '/CommercialClubManagement/BusinessCardGroup/GetIntegralDeductionSetting',
        method: 'get',
    })
}

//修改积分抵扣设置
export function ModifyIntegralDeductionSetting(data) {
    return axios({
        url: '/CommercialClubManagement/BusinessCardGroup/ModifyIntegralDeductionSetting',
        method: 'post',
        data
    })
}

//新增学校
export function AddSchool(data) {
    return axios({
        url: '/CommercialClubManagement/School/Add',
        method: 'post',
        data
    })
}

//获取所有学校
export function GetAllSchool() {
    return axios({
        url: '/CommercialClubManagement/School/GetAll',
        method: 'get',
    })
}

//修改学校
export function UpdateSchool(data) {
    return axios({
        url: '/CommercialClubManagement/School/Update',
        method: 'post',
        data
    })
}

//删除学校
export function DeleteSchool(data) {
    return axios({
        url: '/CommercialClubManagement/School/Delete',
        method: 'post',
        data
    })
}


//修改学校显示顺序
export function ChangeDisplayOrderSchool(data) {
    return axios({
        url: '/CommercialClubManagement/School/ChangeDisplayOrder',
        method: 'post',
        data
    })
}

//获取名片展示设置
export function GetBusinessCardDisplaySetting() {
    return axios({
        url: '/CommercialClubManagement/BusinessCardGroup/GetBusinessCardDisplaySetting',
        method: 'get'
    })
}
//变更名片展示设置
export function ChangeBusinessCardDisplaySetting(data) {
    return axios({
        url: '/CommercialClubManagement/BusinessCardGroup/ChangeBusinessCardDisplaySetting',
        method: 'post',
        data
    })
}

//获取收费类型
export function GetChargeType() {
    return axios({
        url: '/CommercialClubManagement/MemberFee/GetChargeType',
        method: 'get'
    })
}

//变更收费类型
export function ChangeChargeType(data) {
    return axios({
        url: '/CommercialClubManagement/MemberFee/ChangeChargeType',
        method: 'post',
        data
    })
}

//获取所有的企业名称(暂用于企业信息复用)
export function GetCompanies(data) {
    return axios({
        url: '/CommercialClubManagement/Company/GetCompanies',
        method: 'post',
        data
    })
}
//获取企业信息(暂用于企业信息复用)
export function GetCompany(id) {
    return axios({
        url: '/CommercialClubManagement/Company/GetCompany?companyId='+id,
        method: 'get'
    })
}